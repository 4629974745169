import React from 'react'
import Link from 'gatsby-link'
import { Layout } from '../layouts';

const PrivacyPage = (props) => (
  <Layout location={props.location}>
    <div>
      <h2 className="content-h2">Datenschutz</h2>
      <p>
        Bei Nutzung dieser Webseite werden von uns keine personenbezogenen Daten erhoben. <br />
        Sofern Sie per Email mit uns Kontakt aufnehmen, werden Ihre Daten werden nur soweit gespeichert, wie dies für die Abwicklung und Bearbeitung Ihrer Anfrage erforderlich ist. Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter.
    </p>
      <h3 className="content-h3">Server-Log- Files</h3>
      <p>
        Mit dem Zugriff auf diese Website werden durch den Service-Provider die IP-Adressen der Besucher erfasst und in Logfiles gespeichert. Diese IP-Adressen werden vom Service-Provider zur Erkennung und Abwehr von Angriffen maximal sieben Tage gespeichert. Darüber hinaus können allgemeine Informationen über den Zugriff (Datum, Uhrzeit, betrachtete Seite) auf dem Server unseres Webhosters gespeichert werden. Diese Daten gehören nicht zu den personenbezogenen Daten, sondern sind anonymisiert und werden ausschließlich zu statistischen Zwecken ausgewertet.
    </p>

      <h3 className="content-h3">Cookies</h3>
      Cookies werden von uns nicht verwendet.
    
  <h3 className="content-h3">Online-Terminvereinbarung</h3>
      Wenn Sie die Online-Terminvereinbarung verwenden, nutzen Sie einen Service der Firma samedi GmbH. Die für die Abwicklung notwendigen Daten bei der Firma samedi GmbH 10247 Berlin gespeichert. Ausführliche Informationen über die dabei verwendeten Datenschutzrichtlinien finden Sie unter <a href="https://www.samedi.de/datenschutz-patienten.html" className="d-link" target="_blank">https://www.samedi.de/datenschutz-patienten.html</a>

    </div>
  </Layout>
)

export default PrivacyPage
